import * as Helpers from './helpers.js';

/**
 * Sets up mobile navigation. Adds open/close toggle button, adds handler for clicks on it.
 */
export function doMobileNavigation() {
	document.querySelector('html').classList.add('mobile-nav');

	// create the navigation toggle button
		let templateToggleNav = `
			<button id='mainNavToggle' aria-label="Main Menu">
				<span class='line'></span>
				Menu
			</button>
		`;
		document.querySelector('.navigationBar > .l_constrain').insertAdjacentHTML('afterbegin', templateToggleNav);
		let navToggle = document.querySelector('#mainNavToggle');

	// Accessibility enhancements for the dynamic toggling control
		let theMenu = document.querySelector('#site_navigation');
		theMenu.setAttribute("aria-labelledby", "mainNavToggle");

	// handle clicks on the menu toggle button
		navToggle.addEventListener("click", function (e) {
			let newNavStatus;
			let html= document.querySelector('html');
			let navIsOpen   = document.querySelector('html').classList.contains("nav-active"); // returns true|false
			let navBarNav = document.querySelector('.navigationBar nav');

			newNavStatus  = !navIsOpen; // inverts the value
			navToggle.setAttribute("aria-expanded", newNavStatus); // applies new value

			/* Work around being unable to animate from display: hidden; to display: block; etc. */
			if(html.classList.contains('nav-active')) {
				// remove the nav-active, which should now animate
				html.classList.remove("nav-active");

				// and once the animation's done, add the display: none; class.
				setTimeout(()=>{
					navBarNav.classList.add('js-hide-from-keyboard');
				},
				300
				);
			} else {
				// first remove the "display: none"; so it becomes block and animatable
				navBarNav.classList.remove('js-hide-from-keyboard');
				// then add the nav-active, which should now animate
				setTimeout(()=>{
					html.classList.add("nav-active");
				},
				25
				);
			}
		});
}

/**
 * Undoes the changes that doMobileNavigation() makes to the document.
 */
export function undoMobileNavigation() {
	document.querySelector('html').classList.remove('mobile-nav');

	let navMenuToggle = document.querySelector('#mainNavToggle');
	if( navMenuToggle ) {
		document.querySelector('html').classList.remove("nav-active");
		document.querySelector('#mainNavToggle').remove();
		document.querySelector('#site_navigation').removeAttribute('aria-labelledby');
	}
}

/**
 * Initialise and handle the sub-menu reveal behaviour. Also injects a "back button" for each sub-menu so mobile users can navigate back up to the parent.
 */
export function subMenuToggle() {
	let subNavs = document.querySelectorAll('li[data-submenu-status]');

	subNavs.forEach(level => {
		let childUl = level.querySelector(':scope > ul');
		let toggleLink = level.querySelector(':scope > a');

		childUl.insertAdjacentHTML(
			'afterbegin',
			"<li><button role='button'>BACK</button></li>"
		);

		let backButton = childUl.querySelector('button');

		console.log({level, childUl});

		toggleLink.addEventListener(
			'click',
			function(e){
				e.preventDefault();

				let parentLi       = e.currentTarget.closest('li');
				let parentUl       = parentLi.closest('ul');
				let parentSiblings = parentUl.querySelectorAll(":scope > [data-submenu-status]");

				console.log({parentLi, parentUl, parentSiblings});

				parentSiblings.forEach(thing => {
					if( thing != parentLi ) {
						thing.dataset.submenuStatus = 'closed';
					}
				});

				let parentLiStatus = parentLi.dataset.submenuStatus;
				parentLiStatus == 'closed' ?
					(parentLi.dataset.submenuStatus = 'open') :
					(parentLi.dataset.submenuStatus = 'closed');
			}
		);

		backButton.addEventListener('click', event => {
			event.currentTarget.closest('[data-submenu-status]').dataset.submenuStatus = 'closed';
		});
	});
}

/**
 * Closes all navigation structures.
 */
export function dismissAllNav() {
	/* Close the mobile nav */
	document.querySelector('html').classList.remove('nav-active');

	/* Close all other navs */
	const subNavs = document.querySelectorAll('[data-submenu-status]');
	subNavs.forEach(nav => {
		nav.dataset.submenuStatus = "closed";
	});
}

/**
 * Adds an event listener that handles clicks outside the .navigationBar, and triggers dismissAllNav() when matched.
 */
export function watchForDismiss() {
	const navigationBar = document.querySelector('.navigationBar');
	document.addEventListener('click', function (event) {
		if (!navigationBar.contains(event.target)) {
			// the thing that was clicked was not inside the navigationBar div, so we should dismiss all nav!
			dismissAllNav();
		}
	});
}

/**
 * Assess the viewport size and trigger the correct JS as a result. Watch for changes of the viewport size and toggle the behaviour as a result.
 */
export function initNavigation() {
	let screenWidth = window.matchMedia('(max-width: 1023px)');

	// initial load
	subMenuToggle();

	if (screenWidth.matches) {
		doMobileNavigation();
	} else {
		undoMobileNavigation();
	}

	// watch for changes in window size
	screenWidth.addEventListener("change", (e) => {
		if (e.matches) {
			doMobileNavigation();
		} else {
			undoMobileNavigation();
		}
	});

	watchForDismiss();
}

initNavigation();
Helpers.initVAT();
